<template>
  <v-flex>
    <notify-custom :showNotify="show" :type="tipoNotify"
    :message="msgNotify" :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-data-table :page="page" :pageCount="numberOfPages"
    :options.sync="options" :server-items-length="totalItems"
      :loading="loading" :headers="headers" :items="objetos"
      sort-by="created_at" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ txtMessages.txtOrdersNoSync }} {{ nameDropshipper }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details @change="listar()">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <vue-excel-xlsx :data="objExcel" :columns="columns"
          :file-name="'ordersDropshipper'" :file-type="'xlsx'"
            :sheet-name="'orders'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="ma-2 white--text" fab small v-bind="attrs" v-on="on">
                  <v-icon dark>
                    file_download
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ txtMessages.txtExport }}</span>
            </v-tooltip>
          </vue-excel-xlsx>
          <v-dialog v-model="showModalSync" max-width="900px">
            <v-card>
              <v-card-title v-if="resultSync.status" class="headline">
                STATUS: {{ resultSync.status }}
              </v-card-title>
              <v-card-text v-if="resultSync.message">MSG: {{ resultSync.message }}</v-card-text>
              <v-list-item v-for="item in resultSync.order.line_items" :key="item.id" two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.vendor }} {{ item.sku }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.title }} ({{ item.price }})</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{ $moment(item.created_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.register_logs="{ item }">
        <td>
          <h5>{{ item.register_logs.length >= 1 ? item.register_logs[0].date : '' }}</h5>
          {{ item.register_logs.length >= 1 ? item.register_logs[0].content : '' }}
          ({{ item.register_logs.length }})
        </td>
      </template>
      <template v-slot:item.shipping_lines="{ item }">
        <td>
          <p v-for="v in item.shipping_lines" :key="v.number">{{ v.code }}</p>
        </td>
      </template>
      <template v-slot:item.options="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="#ff9800" class="mr-2" v-bind="attrs"
              v-on="on" :loading="item.loading"
                :disabled="item.loading" @click="syncOrderDropshipper(item.id)">
                <v-icon> mdi-sync
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncOrder }}</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      dialog: false,
      showModalSync: false,
      resultSync: {
        order: {
          name: null,
          line_items: [],
          total_price: null,
        },
      },
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false,
        },
        { text: 'order name Dropshipper', value: 'name', sortable: false },
        { text: 'order id', value: 'id', sortable: false },
        { text: langMsg.txtOrderPriceDropshipper, value: 'total_price', sortable: false },
        { text: langMsg.txtOrderShippingLine, value: 'shipping_lines', sortable: false },
        { text: langMsg.txtOrderNote, value: 'note', sortable: false },
        { text: langMsg.logs, value: 'register_logs', sortable: false },
        { text: langMsg.txtOptions, value: 'options', sortable: false },
      ],
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        { label: langMsg.txtOrderName, field: 'name' },
        { label: langMsg.txtOrderIdProvider, field: 'id' },
        { label: langMsg.txtOrderPriceDropshipper, field: 'total_price' },
        { label: langMsg.txtOrderShippingLine, field: 'shipping_lines' },
        { label: langMsg.txtOrderNote, field: 'note' },
      ],
      // eslint-disable-next-line
      nameDropshipper: '',
      nameConfig: '',
      idDropshipper: '',
      data: {},
      // --- events data ---
      loading: false,

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      return val || this.close();
    },
    dialogDelete(val) {
      return val || this.closeDelete();
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
  },
  mounted() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.idDropshipper = this.$route.params.id;
    if (this.$store.state.token) {
      this.getOne(this.idDropshipper);
      this.listar();
    }
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    syncOrderDropshipper(idOrder) {
      this.loading = true;
      // eslint-disable-next-line
      axios.get(`order/sync/single/${this.idDropshipper}/${idOrder}`).then((response) => {
        console.log(response.data);
        this.showModalSync = true;
        this.resultSync = response.data;
        this.loading = false;
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        this.notifyMessage('Error', err.response.data.message);
        return 'fail';
      });
    },
    getOne(id) {
      if (axios.defaults.headers.common.authorization) {
        axios.get(`dropshipper/get/${id}`).then((res) => {
          this.data = res.data.body;
          this.nameDropshipper = `${this.data.name} - ${this.data.contact.name}`;
          this.nameConfig = this.data.config.shop;
          return 'ok';
        });
      }
    },
    listar() {
      this.loading = true;
      this.idDropshipper = this.$route.params.id;
      this.objetos = [];
      let urlConsult = `order/no-sync/${this.idDropshipper}?times=15&unit=days`;
      const search = this.search === '' ? false : this.search;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      if (axios.defaults.headers.common.authorization) {
        axios.get(urlConsult).then((res) => {
          this.loading = false;
          this.objetos = res.data.body;
          this.objExcel = res.data.body.map((a) => {
            const doc = { ...a };
            doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
            return doc;
          });
          this.totalItems = res.data.body.length;
          this.numberOfPages = 1;
        });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
    getColor(result) {
      return result ? 'green' : 'red';
    },
    dateFormatCancelled(value) {
      if (value && (value.reason && value.cancelled_at)) return value.cancelled_at;
      return '';
    },
    reasonFormat(value) {
      if (value?.reason) return value;
      return '';
    },
  },
};
</script>
