<template>
  <v-flex v-if="homeAgents" class="bg-surface-variant">
    <v-parallax src="../assets/ladder_stage_railing_grab.jpg">
      <div class="d-flex flex-column fill-height justify-center align-center text-white">
        <h1 class="text-h4 font-weight-thin mb-4">
          DROPSHIPPING - CATA1OG
        </h1>
        <h4 class="subheading">
          Start your business in a easy way with us!
        </h4>
        <br>
        <v-btn :to="{ name: 'Login' }">
          LOGIN
          <v-icon>
            login
          </v-icon>
        </v-btn>
      </div>
    </v-parallax>
    <br><br><br><br>
    <v-divider></v-divider>
    <br><br>
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h4 class="subheading">
        MAIN PROCESS
      </h4>
    </div>
    <v-row no-gutters>
      <v-col order="2">
        <v-sheet class="pa-2 ma-2">
          <v-card color="#303030">
            <v-card-item name="card-1">
              <v-card-title style="color: beige;">Synchronization
                Exclusive Order Synchronization</v-card-title>
              <v-card-subtitle style="color: beige;">Optimize your sales!</v-card-subtitle>
            </v-card-item>
            <v-card-text style="color: beige;">
              We only synchronize
              orders that include products from our exclusive catalog.
              exclusive catalog. Make sure you make the most of our
              our product catalog and enjoy a smooth and efficient shopping experience!
              a smooth and efficient shopping experience!
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col order="3">
        <v-sheet class="pa-2 ma-2">
          <v-card color="#FAA072">
            <v-card-item name="card-2">
              <v-card-title>Always Up To Date Inventory in Your Store</v-card-title>
              <v-card-subtitle>Your store always ready!</v-card-subtitle>
            </v-card-item>
            <v-card-text>
              We keep the inventory of our products
              constantly updated in your store,
              ensuring you always have the latest and most accurate information.
              Optimize your sales with up-to-date inventory!
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col order="1">
        <v-sheet class="pa-2 ma-2">
          <v-card color="#FAA072">
            <v-card-item name="card-3">
              <v-card-title>Automatic Product Updates</v-card-title>
              <v-card-subtitle>Effortlessly keep your inventory up to date!</v-card-subtitle>
            </v-card-item>
            <v-card-text>
              Products are updated automatically,
              activating this process only when you request it.
              Enjoy the convenience of always having the latest
              the latest information at your request.
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <br><br>
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <v-btn :to="{ name: 'Login' }">
        LOGIN
        <v-icon>
          login
        </v-icon>
      </v-btn>
    </div>
  </v-flex>
  <v-flex v-else class="bg-surface-variant">
    <v-row no-gutters>
      <v-col order="1">
        <v-sheet class="pa-2 ma-2">
        </v-sheet>
      </v-col>
    </v-row>

    <div v-if="objShop" style="float: left;" name="card-4">
      <v-card-title>{{ objShop.name }}</v-card-title>
      <v-card-subtitle>Register since {{ formatDate(objShop.created_at) }}</v-card-subtitle>
      <span class="v-card__subtitle">{{ objShop.contact.email }}</span> <br>
      <span class="v-card__subtitle">{{ objShop.contact.phone }}</span> <br>
    </div>
    <!-- <div v-if="objShop"   style="float: right;">
      <v-row>
        <table name="table-1">
          <thead>
            <tr>
              <th style="min-width: 100px" id="inventory" class="text-left">
                Inventory
              </th>
              <th style="min-width: 100px" id="order" class="text-left">
                Orders
              </th>
              <th style="min-width: 100px" id="product" class="text-left">
                Products
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> <v-switch v-model="objShop.inventoryEnabled" color="orange" hide-details
                  @click="activeInactiveInvShow(objShop.inventoryEnabled ? 1 : 2, objShop)">
                </v-switch></td>
              <td> <v-switch v-model="objShop.enabled" color="red" hide-details
                  @click="activeInactiveOrderShow(objShop.enabled ? 1 : 2, objShop)">
                </v-switch></td>
              <td>
                <v-btn icon class="mr-2" color="blue"
                  @click="showConfirmModal(txtMessages.txtQtSyncProducts, 'product', objShop)">
                  <v-icon> mdi-sync </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-row>
    </div> -->
    <br><br><br><br>
    <br><br>
    <v-data-table v-if="objShop"  :page="page" :pageCount="numberOfPages"
    :options.sync="options" :server-items-length="totalItems"
      :loading="loading" :headers="headers" :items="objetos"
      sort-by="created_at" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ txtMessages.txtOrders }}
            {{ nameDropshipper }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details @change="listar()">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{ $moment(item.created_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.cancelled_object.cancelled_at="{ item }">
        <td>
          <template v-if="item.cancelled">
            {{ $moment(item.cancelled_object.cancelled_at).format('YYYY-MM-DD HH:MM') }}
          </template>
        </td>
      </template>
      <template v-slot:item.fulfilled="{ item }">
        <v-chip :color="getColor(item.fulfilled)" dark>
          {{ item.fulfilled ? "fulfilled" : "unfulfilled" }}
        </v-chip>
      </template>
      <template v-slot:item.cancelled="{ item }">
        <v-chip :color="getColor(item.cancelled)" dark>
          {{ item.cancelled }}
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
    <v-row no-gutters>
    </v-row>
    <br><br>
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
    </div>
    <br><br><br><br>
    <v-divider></v-divider>
    <br><br>
  </v-flex>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import langMsg from '../config/generalMessages';

export default {
  data() {
    return {
      txtMessages: langMsg,
      email: '',
      password: '',
      errorm: null,
      show1: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: v => v.length >= 8 || 'Min 8 characters',
      },
      homeAgents: true,
      objShop: null,

      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false,
        },
        { text: langMsg.txtOrderNameProvider, value: 'origin_order_name', sortable: false },
        { text: langMsg.txtorderNameDropshipper, value: 'final_order_name', sortable: false },
        { text: langMsg.txtFulfilled, value: 'fulfilled', sortable: false },
      ],
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        { label: langMsg.txtOrderName, field: 'origin_order_name' },
        { label: langMsg.txtOrder, field: 'final_order_name' },
        { label: langMsg.txtFulfilled, field: 'fulfilled' },
        { label: langMsg.txtCancelled, field: 'cancelled' },
      ],
      // eslint-disable-next-line
      nameDropshipper: '',
      nameConfig: '',
      idDropshipper: '',
      data: {},
      // --- events data ---
      loading: false,

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
      ulrShop: null,
      idShop: null,

      confirmModal: 0,
      msgConfirm: '',
      confirmId: '',
      typeSync: '',
    };
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'login-layout');
    const { shop } = this.$route.query;
    this.homeAgents = !shop || false;
    this.ulrShop = shop.replace('.myshopify.com', '');
    if (shop) localStorage.setItem('lang', 'english');
    this.getShop(this.ulrShop);
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    ingresar() {
      axios.post('/login', { email: this.email, password: this.password }).then((response) => {
        const { data } = response;
        this.$store.dispatch('saveToken', data);
        if (data.obj.rol === 'datafeed') {
          this.$router.push({ name: 'VariantsDropshipper' });
        } else {
          this.$router.push({ name: 'Stats' });
        }
      }).catch((err) => {
        // throw err;
        this.errorm = null;
        this.errorm = err.response.status === 404 || err.response.status === 401 ? 'Correo o contraseña incorrecta' : 'Server error';
      });
    },
    getShop(shop) {
      axios.get(`/dropshipper/shop/${shop}`).then((response) => {
        const { data } = response;
        this.objShop = data.body;
        this.idShop = this.objShop._id;
      }).catch((err) => {
        // throw err;
        this.errorm = null;
        this.errorm = err.response.status === 404 || err.response.status === 401 ? 'Correo o contraseña incorrecta' : 'Server error';
      });
    },

    listar() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const items = itemsPerPage || 100;
      const pageNumber = page || 1;
      this.idDropshipper = this.$route.params.id;
      this.objetos = [];
      if (!this.idShop) {
        return;
      }
      let urlConsult = `myorder/get/shop/${this.idShop}?limit=${items}&page=${pageNumber}`;
      const search = this.search === '' ? false : this.search;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        this.loading = false;
        this.objetos = res.data.body.docs;
        this.objExcel = res.data.body.docs.map((a) => {
          const doc = { ...a };
          doc.fulfilled = doc.fulfilled ? 'fulfilled' : 'unfulfilled';
          doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
          return doc;
        });
        this.totalItems = res.data.body.totalDocs;
        this.numberOfPages = res.data.body.pages;
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
    getColor(result) {
      return result ? 'green' : 'red';
    },
    dateFormatCancelled(value) {
      if (value && (value.reason && value.cancelled_at)) return value.cancelled_at;
      return '';
    },
    reasonFormat(value) {
      if (value?.reason) return value;
      return '';
    },
    async activeInactiveInvShow(action, item) {
      this.aiNameInv = item.name;
      this.aiIdInv = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactiveInv();
        if (result === 'ok') {
          this.aiActionInv = 2;
          this.aiModalInv = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activeInv();
        if (result === 'ok') {
          this.aiActionInv = 1;
          this.aiModalInv = 1;
        }
      }
    },
    showConfirmModal(msg, type, item) {
      this.confirmModal = 1;
      this.msgConfirm = msg;
      this.confirmId = item._id;
      this.typeSync = type;
    },
    async activeInactiveOrderShow(action, item) {
      this.aiNameOrder = item.name;
      this.aiIdOrder = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactiveOrder();
        if (result === 'ok') {
          this.aiActionOrder = 2;
          this.aiModalOrder = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activeOrder();
        if (result === 'ok') {
          this.aiActionOrder = 1;
          this.aiModalOrder = 1;
        }
      }
    },
  },
  watch: {
    dialog(val) {
      return val || this.close();
    },
    dialogDelete(val) {
      return val || this.closeDelete();
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
};
</script>
