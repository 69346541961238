<template>
<v-flex>
  <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-card
    class="mx-auto"
  >
  <v-card-title>
        {{txtMessages.txtCreateTitle}} Dropshipper
  </v-card-title>
        <v-form ref="form">
            <v-container>
                <v-row justify="space-between">
                <v-col
                    cols="12"
                    md="4"
                >
                <v-text-field
            v-model="nameDropshipper"
            :rules="[rules.required]"
            :label="txtMessages.lblCompanyName"
            required
          ></v-text-field>
          <v-text-field
            v-model="emailContact"
            :rules="[rules.email, rules.required]"
            :label="txtMessages.lblContactEmail"
            required
          ></v-text-field>
          <v-text-field
            v-model="nameContact"
            :rules="[rules.required]"
            :label="txtMessages.lblContactName"
            required
          ></v-text-field>
          <v-text-field
            v-model="phoneContact"
            type="tel"
            :rules="[rules.required]"
            :label="txtMessages.lblContactPhone"
          ></v-text-field>
           <v-text-field
            v-model="shopifyIdContact"
            :rules="[rules.required]"
            label="Shopify Id"
            required
          ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
          <v-text-field
            v-model="accessToken"
            :rules="[rules.required]"
            label="access token"
            required
          ></v-text-field>
          <v-text-field
            v-model="apiKey"
            :rules="[rules.required]"
            label="api key"
            required
          ></v-text-field>
          <v-text-field
            v-model="shopifyName"
            :rules="[rules.required]"
            label="shop name (shopify)"
            required
          ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="reset">
        {{txtMessages.txtCancel}}
    </v-btn>
    <v-btn
    :disabled="!formIsValid"
    color="blue darken-1"
    text @click="guardar">
    {{txtMessages.txtSave}}
    </v-btn>
    </v-card-actions>
</v-form>
  </v-card>
</v-flex>
</template>
<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      nameDropshipper: '',
      emailContact: '',
      nameContact: '',
      phoneContact: '',
      shopifyIdContact: '',
      billingCountry: '',
      billingProvince: '',
      billingCity: '',
      billingAddress: '',
      accessToken: '',
      apiKey: '',
      shopifyName: '',

      valida: 0,
      validaMensaje: [],

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',

      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          // eslint-disable-next-line
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },

    };
  },
  computed: {
    formIsValid() {
      return (
        this.nameDropshipper
        && this.emailContact
        && this.nameContact
        && this.phoneContact
        && this.shopifyIdContact
        && this.accessToken
        && this.apiKey
        && this.shopifyName
      );
    },
  },

  watch: {
  },

  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.showuser();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    showuser() {
      return this.$store.state.user;
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      this.valida = 0;
      this.validaMensaje = [];
      this.nameDropshipper = '';
      this.nameContact = '';
      this.emailContact = '';
      this.phoneContact = '';
      this.shopifyIdContact = '';
      this.accessToken = '';
      this.apiKey = '';
      this.shopifyName = '';
      this.billingCountry = '';
      this.billingProvince = '';
      this.billingCity = '';
      this.billingAddress = '';
      this.errorMsg = '';
      this.showError = 0;
      this.successMsg = '';
      this.showSuccess = 0;
    },
    guardar() {
      // nuevo
      if (!(this.nameDropshipper
      && this.emailContact
      && this.nameContact
      && this.phoneContact
      && this.shopifyIdContact
      && this.accessToken
      && this.apiKey
      && this.shopifyName)) {
        return;
      }
      const newdoc = {
        name: this.nameDropshipper,
        contact: {
          email: this.emailContact,
          phone: this.phoneContact,
          name: this.nameContact,
          shopify_id: this.shopifyIdContact,
        },
        config: {
          access_token: this.accessToken,
          shopify_api_key: this.apiKey,
          shop: this.shopifyName,
          verbose: false,
        },
        created_by: this.$store.state.user.email,
      };
      if (this.billingCountry !== '') {
        newdoc.billing = {
          country: this.billingCountry,
          province: this.billingProvince,
          city: this.billingCity,
          address: this.billingAddress,
        };
      }
      axios.post('dropshipper/create', newdoc).then((response) => {
        this.clean();
        this.notifyMessage('Success', 'Created');
        return response.data;
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        this.onError(err);
        this.notifyMessage('Error', err.message);
        return 'fail';
      });
    },
  },
};
</script>
