<template>
  <v-container>
    <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-stepper v-model="e13" vertical class="grey--text text--darken-3">
      <v-stepper-step step="1" :complete="e13 > 0">
        {{txtMessages.txtCustomerShopify}}
      </v-stepper-step>
      <v-stepper-content step="1">
        <div class="d-flex justify-center">
            <v-col cols="12" sm="6">
              <v-text-field v-model="emailShopify" :rules="emailRules"
              :label="txtMessages.lblCustomerEmail" required></v-text-field>
            </v-col>
           <div class="d-flex justify-center">
          <v-btn color="info"
          :loading="loading"
          @click="getCustomer()"
           > {{txtMessages.txtSearch}}
            </v-btn>
        </div>
           <v-flex xs12 sm12 md12 v-show="valida">
              <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
              </div>
            </v-flex>
        </div>
        <div class="d-flex justify-start">
          <v-btn @click="e13 = 2" color="primary" :disabled="!validSearchCustomer">
            {{txtMessages.txtContinue}}</v-btn>
        </div>
      </v-stepper-content>
       <v-stepper-step step="2" :complete="e13 > 1">
         {{txtMessages.txtContactInformation}}
       </v-stepper-step>
      <v-stepper-content step="2">
        <v-form ref="contactForm" v-model="valid" :lazy-validation="lazy">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="companyName" :rules="nameRules"
              :label="txtMessages.lblCompanyName" required></v-text-field>
              <v-text-field v-model="contactName" :rules="nameRules"
              :label="txtMessages.lblContactName" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="contactEmail" :rules="emailRules"
                :label="txtMessages.lblContactEmail" required>
              </v-text-field>
              <v-text-field v-model="contactPhone" :rules="phoneRules"
              :label="txtMessages.lblContactPhone" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-start">
          <v-btn @click="validate" color="primary" >{{txtMessages.txtContinue}}</v-btn>
          <v-btn text @click="e13 = 1" color="accent">{{txtMessages.txtBack}}</v-btn>
          <v-btn text @click="reset">{{txtMessages.txtCancel}}</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step step="3" :complete="e13 > 2">{{txtMessages.txtSingInShopify}}</v-stepper-step>
      <v-stepper-content step="3">
        <div class="d-flex justify-center">
            <v-col cols="12" sm="6">
              example: myshopexample.myshopify.com
              <v-text-field v-model="urlShop" :rules="nameRules"
              :label="txtMessages.lblUrlShop" required></v-text-field>
                <template v-if="getToken">
                  <div><strong class="success--text">
                    Shopify</strong> {{txtMessages.txtPermissionsObtained}}</div>
                </template>
            </v-col>
           <div class="d-flex justify-center">
          <v-btn @click="validLogin()" color="info"
          :href="`${link.href}?shop=${urlShop}&type=install`" :target="link.target"
           > {{ link.name }}
            </v-btn>
        </div>
           <v-flex xs12 sm12 md12 v-show="valida">
              <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
              </div>
            </v-flex>
        </div>
        <div class="d-flex justify-start">
          <v-btn text @click="e13 = 2" color="accent">{{txtMessages.txtBack}}</v-btn>
          <v-btn @click="saveDropshipper()" color="primary"
          :disabled="!getToken" >{{txtMessages.txtSave}}</v-btn>
        </div>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      validSearchCustomer: false,
      emailShopify: '',
      contactShopifyId: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      companyName: '',
      billingCountry: '',
      billingProvince: '',
      billingCity: '',
      billingAddress: '',
      configAccessToken: '',
      configApiKey: '',
      configShop: '',
      getToken: false,
      dataDroshipperExist: {},

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
      // --------------------------
      e13: 1,
      loading: false,
      nameRules: [
        v => !!v || 'El nombre es requerido.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 15 caracteres.',
      ],
      email: '',
      urlShop: '',
      emailRules: [
        value => !!value || 'El e-mail es requerido.',
        value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'El e-mail es inválido.',
      ],
      phoneRules: [
        value => !!value || 'El teléfono es requerido.',
      ],
      link: { name: 'Log in', href: 'https://dropshipping-api.cata1og.com/test/install', target: '_blank' },
      valid: true,
      snackbar: false,
      timeout: 5000,
      lazy: false,
      validaMensaje: [],
      valida: 0,
    };
  },
  watch: {
  },
  created() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
  },
  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    validate() {
      this.$refs.contactForm.validate();
      this.e13 = 3;
    },
    reset() {
      this.$refs.contactForm.reset();
    },
    async getCustomer() {
      await axios.get(`dropshipper/search/customer/in-catalog?email=${this.emailShopify}`).then(response => {
        const res = response.data;
        this.dataDroshipperExist = res.dropshipper.body;
        if (res.cliente.body.length > 0) {
          this.validSearchCustomer = true;
          this.contactShopifyId = res.cliente.body[0].id;
          this.contactName = `${res.cliente.body[0].first_name} ${res.cliente.body[0].last_name}`;
          this.contactPhone = res.cliente.body[0].default_address.phone;
          this.contactEmail = res.cliente.body[0].email;
          this.companyName = res.cliente.body[0].default_address.company;
          this.billingCountry = res.cliente.body[0].default_address.country;
          this.billingProvince = res.cliente.body[0].default_address.province;
          this.billingCity = res.cliente.body[0].default_address.city;
          this.billingAddress = res.cliente.body[0].default_address.address1;
          return;
        }
        const err = new Error('Not found customer in catalog by this email');
        this.onError(err);
        this.notifyMessage('Error', err.message);
      }).catch((error) => {
        this.notifyMessage('Error', error.message);
      }).finally(() => {
        this.loading = false;
      });
    },
    async saveDropshipper() {
      if (this.contactShopifyId !== '' && this.configShop !== '') {
        await axios.post('/dropshipper/create',
          {
            store: 0,
            name: this.companyName,
            created_by: this.$store.state.user.email,
            updated_by: this.$store.state.user.email,
            contact: {
              email: this.contactEmail,
              phone: this.contactPhone,
              name: this.contactName,
              shopify_id: this.contactShopifyId,
            },
            config: {
              access_token: this.configAccessToken,
              shopify_api_key: this.configApiKey,
              shop: this.configShop,
            },
          })
          .then(response => {
            this.clear();
            this.notifyMessage(response ? 'Success' : 'Error', 'Created');
            this.e13 = 1;
          }).catch(error => {
            this.notifyMessage('Error', error.message);
          });
      } else {
        const err = new Error("Something's wrong");
        this.notifyMessage('Error', err.message);
      }
    },
    getDraft() {
      const shop = this.urlShop.split('.myshopify')[0];
      axios.get(`dropshipper/get/draft/dropshipper?shop=${shop}`).then(response => {
        this.configAccessToken = response.data.access_token;
        this.configApiKey = response.data.shopify_api_key;
        this.configShop = response.data.shop;
        this.loading = false;
        return 'ok';
      });
    },
    validLogin() {
      this.statusCode = 0;
      const url = this.urlShop;
      this.loading = true;
      axios.get(`http://localhost:3000/shopify/install?shop=${url}&type=valid`).then(async response => {
        this.statusCode = response.status;
        await this.getDraft();
        setTimeout(() => {
          if (this.statusCode === 200 && this.configAccessToken !== '') {
            this.getToken = true;
          }
        }, 3000);
      });
    },
    clear() {
      this.customers = '';
      this.loading = false;
      this.validSearchCustomer = '';
      this.emailShopify = '';
      this.contactShopifyId = '';
      this.contactName = '';
      this.contactPhone = '';
      this.contactEmail = '';
      this.companyName = '';
      this.billingCountry = '';
      this.billingProvince = '';
      this.billingCity = '';
      this.billingAddress = '';
      this.configAccessToken = '';
      this.configApiKey = '';
      this.configShop = '';
      this.getToken = false;
    },
  },
};
</script>
